import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { utils, writeFile } from 'xlsx';

import { acuerdoActions } from '_store';

import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';

import SharedService from '../service/shared.service';

const RegatasAcuerdo = () => {
    const dispatch = useDispatch();
    const [activeIndex, setActiveIndex] = useState(0);
    const { loading } = useSelector((state) => state.acuerdos.status);

    let acuerdos = useSelector((state) => {
        return activeIndex === 0 ? state.acuerdos.data.filter((item) => item.status) : state.acuerdos.data.filter((item) => item.acuerdo > 0 && !item.status);
    });

    const initRegistros = (data) => {
        return data.map((item) => ({
            ...item
        }));
    };

    const getRegistros = (data) => {
        return [...(data || [])].map((d) => {
            d.fechaCreado = new Date(d.fechaCreado);
            d.fechaEditado = new Date(d.fechaEditado);
            d.fechaAprobado = new Date(d.fechaAprobado);
            d.fechaInicio = new Date(d.fechaInicio);
            d.fechaFin = new Date(d.fechaFin);
            d.fechaacta = new Date(d.fechaacta);
            return d;
        });
    };

    let data = initRegistros(acuerdos);
    data = getRegistros(data);

    const [dialog, setDialog] = useState(false);
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [registro, setRegistro] = useState(null);
    const [movimientos, setMovimientos] = useState(null);

    const dt = useRef(null);
    const isMounted = useRef(false);
    const movimientosRef = useRef(movimientos);

    const sharedService = new SharedService();

    useEffect(() => {
        if (!isMounted.current) {
            // Esta es la primera renderización (montaje del componente)
            dispatch(acuerdoActions.getAll());
            initFilters();
            isMounted.current = true;
        } else {
            // Aquí puedes manejar lógica específica para cuando movimientos cambia
            movimientosRef.current = movimientos;
        }
    }, [dispatch, movimientos]);

    const getExportar = (data) => {
        return [...(data || [])].map((d) => ({
            codigo: d.codigo,
            acuerdo: d.acuerdo,
            acta: d.acta,
            fechaacta: new Date(d.fechaacta),
            titulo: d.titulo,
            descripcion: d.descripcion,
            comentario: d.comentario,
            centrodecosto: d.centrodecosto,
            sede: d.sede,
            area: d.area,
            director: d.director,
            jefatura: d.jefatura,
            encargado: d.encargado ? encargados.find((f) => f.CO_TRAB === d.encargado).DE_TRAB : d.encargado,
            montosoles: d.montosoles,
            montodolares: d.montodolares,
            fechaInicio: new Date(d.fechaInicio),
            fechaFin: new Date(d.fechaFin),
            estado: d.estado,
            situacion: d.situacion,
            detalle: d.detalle,
            observacion: d.observacion,
            usuarioCreado: d.usuarioCreado,
            fechaCreado: new Date(d.fechaCreado),
            usuarioEditado: d.usuarioEditado,
            fechaEditado: new Date(d.fechaEditado),
            usuarioAprobado: d.usuarioAprobado,
            fechaAprobado: new Date(d.fechaAprobado)
        }));
    };

    const estado = ['REGISTRADO', 'ACTUALIZADO', 'EN REVISIÓN', 'OBSERVADO', 'CANCELADO', 'APROBADO'];
    const situacion = ['PENDIENTE', 'EN EJECUCIÓN', 'CULMINADO'];
    const sede = ['CHORRILLOS', 'LA PUNTA', 'LA CANTUTA', 'SAN ANTONIO', 'VILLA DEPORTIVA', 'PARACAS'];

    const encargados = [
        { CO_TRAB: '08803136', DE_TRAB: 'ANGULO MIRANDA LUIS GUILLERMO' },
        { CO_TRAB: '42253092', DE_TRAB: 'ALVAREZ GALVEZ RAFAEL ANTONIO' },
        { CO_TRAB: '42409272', DE_TRAB: 'ARCE PEÑA EMELDA OFELIA' },
        { CO_TRAB: '10475250', DE_TRAB: 'BOHORQUEZ CAIRO KATTIA JOSEFINA' },
        { CO_TRAB: '40013088', DE_TRAB: 'BORDO MOLINA MAGALLY GIANNINA' },
        { CO_TRAB: '09582147', DE_TRAB: 'BAUDACCIO DIEGUEZ RAUL FERNANDO' },
        { CO_TRAB: '09864038', DE_TRAB: 'BAILETTI MC GREGOR	ERIKA LISSET' },
        { CO_TRAB: '42630586', DE_TRAB: 'CARDENAS HIDALGO ROXANA BELINDA' },
        { CO_TRAB: '43322782', DE_TRAB: 'CCAHUIN YUPANQUI OSCAR' },
        { CO_TRAB: '25830138', DE_TRAB: 'CHIROQUE SANDOVAL GANY RICHARD' },
        { CO_TRAB: '43364095', DE_TRAB: 'COBOS CHAVARRI SERGIO' },
        { CO_TRAB: '40363500', DE_TRAB: 'SALCEDO ECHEVARRIA RAUL FRANCISCO' },
        { CO_TRAB: '06636351', DE_TRAB: 'HEREDIA MATIENZO OLGA ROSARIO' },
        { CO_TRAB: '47831825', DE_TRAB: 'TAMURA CIEZA SARA ANTONELLA' },
        { CO_TRAB: '41297654', DE_TRAB: 'MEDINA DIONISIO ANGIE ELIZABETH' },
        { CO_TRAB: '10797939', DE_TRAB: 'NARANJO SHERMAN SILVANA ROSA' },
        { CO_TRAB: '40229038', DE_TRAB: 'NECOCHEA KONJA LUZ MARIA' },
        { CO_TRAB: '47160883', DE_TRAB: 'PINTO VARGAS ALONDRA CAROLINA' },
        { CO_TRAB: '06666809', DE_TRAB: 'QUINO PEREZ LUIS ENRIQUE' },
        { CO_TRAB: '43352845', DE_TRAB: 'RAMIREZ MALASQUEZ PEDRO ANIBAL' },
        { CO_TRAB: '09844964', DE_TRAB: 'RAUCANA LLANTOY ISMAEL LEANDRO' },
        { CO_TRAB: '07644399', DE_TRAB: 'PEREZ PORTUGAL JOSE ENRIQUE' },
        { CO_TRAB: '09289770', DE_TRAB: 'ROMERO CENTENO SUSANA HILDA' },
        { CO_TRAB: '25664866', DE_TRAB: 'SANCHEZ ARROBA JUAN CARLOS' },
        { CO_TRAB: '15345651', DE_TRAB: 'SANTINI ALVARADO MARLENE JUDITH' },
        { CO_TRAB: '10326034', DE_TRAB: 'TAPIA ESCARCENA NIELSEN MIGUEL' },
        { CO_TRAB: '40972865', DE_TRAB: 'VALDIVIESO ANCAJIMA RICARDO ERNESTO' },
        { CO_TRAB: '31650246', DE_TRAB: 'VILLAFANA PEREZ OSCAR JAIME' },
        { CO_TRAB: '43537317', DE_TRAB: 'VILLANUEVA LOPEZ RAUL DIEGO' },
        { CO_TRAB: '08058908', DE_TRAB: 'YZENA SALINAS YOSHIKO ROXANA' },
        { CO_TRAB: '42054208', DE_TRAB: 'ZEGARRA VARGAS ZAIL JESUS' },
        { CO_TRAB: '18123671', DE_TRAB: 'AGUILAR CALVO ALFONSO BUENAVENTURA' },
        { CO_TRAB: '10494968', DE_TRAB: 'BLUME ESPEJO ALFONSO HERNAN' },
        { CO_TRAB: '10866047', DE_TRAB: 'BODERO COELHO ALBERTO ALEJANDRO' },
        { CO_TRAB: '42411227', DE_TRAB: 'CABALLERO DE LAMBARRI MARIANA' },
        { CO_TRAB: '40412160', DE_TRAB: 'ESTELA RANTES YESSENIA KATHERIN' },
        { CO_TRAB: '07928801', DE_TRAB: 'FRIAS KURISAKI MANUEL MARCIAL' },
        { CO_TRAB: '25683836', DE_TRAB: 'GERMAN CABRERA CRISTELH ETHEL' },
        { CO_TRAB: '09933292', DE_TRAB: 'LAMA GARCIA GIANCARLO' },
        { CO_TRAB: '09817259', DE_TRAB: 'VALENCIA HAYA DE LA TORRE ROCIO' },
        { CO_TRAB: '40721438', DE_TRAB: 'VARGAS VALENZUELA LINDA LIDA' }
    ];

    /* by Fernando */
    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            acuerdo: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }]
            },
            codigo: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }]
            },
            centrodecosto: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }]
            },
            acta: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
            },
            fechaacta: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            },
            area: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
            },
            jefatura: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
            },
            sede: { value: null, matchMode: FilterMatchMode.EQUALS },
            encargado: { value: null, matchMode: FilterMatchMode.IN },
            titulo: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
            },
            estado: { value: null, matchMode: FilterMatchMode.EQUALS },
            situacion: { value: null, matchMode: FilterMatchMode.EQUALS },
            fechaInicio: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            },
            fechaFin: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            },
            fechaCreado: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            },
            fechaEditado: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            }
        });
        setGlobalFilterValue('');
    };

    const clearFilter = () => {
        initFilters();
    };

    const formatDate = (value) => {
        if (value) {
            return new Date(value).toLocaleDateString('es-PE', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });
        } else {
            return 'Fecha no disponible';
        }
    };

    const encargadoBody = (rowData) => {
        let encargado = encargados.find((f) => f.CO_TRAB === rowData.encargado).DE_TRAB;

        return (
            <React.Fragment>
                <img
                    alt={encargado}
                    src={`assets/demo/images/avatar/${rowData.encargado}.png`}
                    onError={(e) => (e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png')}
                    width={32}
                    style={{ verticalAlign: 'middle' }}
                />
                <span style={{ marginLeft: '.5em', verticalAlign: 'middle' }} className="image-text">
                    {encargado}
                </span>
            </React.Fragment>
        );
    };

    const sedeBody = (rowData) => {
        return <span className="font-semibold">{rowData.sede}</span>;
    };

    const sedeFilter = (options) => {
        return <Dropdown value={options.value} options={sede} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={sedeItem} placeholder="Seleccione sede/filial" className="p-column-filter" showClear />;
    };

    const sedeItem = (option) => {
        return <span className="font-semibold">{option}</span>;
    };

    const creadoBody = (rowData) => {
        return formatDate(rowData.fechaCreado);
    };

    const actaBody = (rowData) => {
        return formatDate(rowData.fechaacta);
    };

    const dateFilter = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const estadoBody = (rowData) => {
        return <span className={`acuerdo-badge estado-` + (rowData.estado === 'EN REVISIÓN' ? 'EN_REVISIÓN' : `${rowData.estado}`)}>{rowData.estado}</span>;
    };

    const estadoFilter = (options) => {
        return <Dropdown value={options.value} options={estado} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={estadoItem} placeholder="Seleccione estado" className="p-column-filter" showClear />;
    };

    const estadoItem = (option) => {
        return <span className={`acuerdo-badge estado-` + (option === 'EN REVISIÓN' ? 'EN_REVISIÓN' : `${option}`)}>{option}</span>;
    };

    const situacionBody = (rowData) => {
        return <span className={`acuerdo-badge situacion-` + (rowData.situacion === 'EN EJECUCIÓN' ? 'EN_EJECUCIÓN' : `${rowData.situacion}`)}>{rowData.situacion}</span>;
    };

    const situacionFilter = (options) => {
        return <Dropdown value={options.value} options={situacion} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={situacionItem} placeholder="Seleccione situación" className="p-column-filter" showClear />;
    };

    const situacionItem = (option) => {
        return <span className={`acuerdo-badge situacion-` + (option === 'EN EJECUCIÓN' ? 'EN_EJECUCIÓN' : `${option}`)}>{option}</span>;
    };

    const handleExport = () => {
        let datos = getExportar(data);
        const headings = [
            [
                'CODIGO',
                'ACUERDO',
                'ACTA',
                'FECHA ACTA',
                'TITULO',
                'DESCRIPCION',
                'COMENTARIO',
                'CENTRO DE COSTO',
                'SEDE',
                'AREA',
                'DIRECTOR',
                'JEFATURA',
                'ENCARGADO',
                'MONTO SOLES',
                'MONTO DOLARES',
                'FECHA INICIO',
                'FECHA FIN',
                'ESTADO',
                'SITUACION',
                'DETALLE',
                'OBSERVACION',
                'USUARIO CREADO',
                'FECHA CREADO',
                'USUARIO EDITADO',
                'FECHA EDITADO',
                'USUARIO APROBADO',
                'FECHA APROBADO'
            ]
        ];
        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
        utils.sheet_add_aoa(ws, headings);
        utils.sheet_add_json(ws, datos, { origin: 'A2', skipHeader: true });
        utils.book_append_sheet(wb, ws, 'REPORTE');
        writeFile(wb, 'REPORTE.xlsx');
    };

    /* by Fernando */
    const getMovimientos = async (codigo) => {
        try {
            const res = await sharedService.getMovimientos(codigo);
            setMovimientos(res.datos);
        } catch (error) {
            // Manejar errores si es necesario
            console.error('Error al obtener el movimiento', error);
        }
    };

    const showDialog = (rowData) => {
        getMovimientos(rowData.codigo);
        setRegistro(rowData);
        setDialog(true);
    };

    const hideDialog = () => {
        setDialog(false);
        setMovimientos(null);
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-download" className="p-button-help" onClick={handleExport} disabled={loading} />
            </React.Fragment>
        );
    };

    /* by Fernando */
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = (titulo) => {
        return (
            <div className="table-header">
                <h5 className="mx-0 my-1">{titulo}</h5>

                <div className="flex justify-content-between">
                    <Button type="button" icon="pi pi-filter-slash" label="Limpiar filtro" className="p-button-outlined mr-2" onClick={clearFilter} />
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Buscar palabra clave" />
                    </span>
                </div>
            </div>
        );
    };

    const header1 = renderHeader('Control de pre-acuerdos');
    const header2 = renderHeader('Control de acuerdos');
    /* by Fernando */

    return (
        <>
            <div className="grid table-demo">
                <div className="col-12">
                    <div className="card">
                        <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>

                        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                            <TabPanel header="PRE-ACUERDOS">
                                <DataTable
                                    ref={dt}
                                    loading={loading}
                                    value={data}
                                    paginator
                                    className="p-datatable-gridlines"
                                    showGridlines
                                    rows={10}
                                    dataKey="_id"
                                    filters={filters}
                                    filterDisplay="menu"
                                    globalFilterFields={['area', 'sede', 'titulo', 'estado', 'situacion']}
                                    header={header1}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                                    responsiveLayout="scroll"
                                    emptyMessage="No hay registros."
                                >
                                    <Column
                                        field="codigo"
                                        header="CODIGO"
                                        dataType="numeric"
                                        style={{ minWidth: '6rem', textAlign: 'center', cursor: 'pointer' }}
                                        sortable
                                        filter
                                        filterPlaceholder="Buscar por código"
                                        body={(rowData) => <div onClick={() => showDialog(rowData)}>{rowData.codigo}</div>}
                                    />
                                    <Column field="centrodecosto" header="C.COSTO" dataType="numeric" style={{ minWidth: '10rem', textAlign: 'center' }} sortable filter filterPlaceholder="Buscar por centro de costo" />
                                    <Column field="area" header="AREA" style={{ minWidth: '14rem', textAlign: 'center' }} sortable filter filterPlaceholder="Buscar por área" />
                                    <Column field="jefatura" header="JEFATURA" style={{ minWidth: '14rem', textAlign: 'center' }} sortable filter filterPlaceholder="Buscar por jefatura" />
                                    <Column
                                        header="SEDE/FILIAL"
                                        filterField="sede"
                                        showFilterMatchModes={false}
                                        filterMenuStyle={{ width: '6rem' }}
                                        style={{ minWidth: '6rem', textAlign: 'center' }}
                                        body={sedeBody}
                                        filter
                                        filterElement={sedeFilter}
                                    />
                                    <Column header="ENCARGADO" filterField="encargado" showFilterMatchModes={false} filterMenuStyle={{ width: '18rem' }} style={{ minWidth: '18rem' }} body={encargadoBody} />
                                    <Column field="titulo" header="TITULO" style={{ minWidth: '18rem' }} sortable filter filterPlaceholder="Buscar por título" />
                                    <Column field="estado" header="ESTADO" filterMenuStyle={{ width: '8rem' }} style={{ minWidth: '6rem', textAlign: 'center' }} body={estadoBody} sortable filter filterElement={estadoFilter} />
                                    <Column header="REGISTRADO" filterField="fechaCreado" dataType="date" style={{ minWidth: '8rem', textAlign: 'center' }} body={creadoBody} filter filterElement={dateFilter} />
                                </DataTable>
                            </TabPanel>
                            <TabPanel header="ACUERDOS">
                                <DataTable
                                    ref={dt}
                                    loading={loading}
                                    value={data}
                                    showGridlines
                                    filters={filters}
                                    filterDisplay="menu"
                                    dataKey="_id"
                                    paginator
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    globalFilterFields={['area', 'sede', 'titulo', 'estado', 'situacion']}
                                    header={header2}
                                    responsiveLayout="scroll"
                                    className="p-datatable-gridlines"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                                    emptyMessage="No hay registros."
                                >
                                    <Column
                                        field="acuerdo"
                                        header="ACUERDO"
                                        sortable
                                        filter
                                        filterPlaceholder="Buscar por acuerdo"
                                        style={{ minWidth: '6rem', textAlign: 'center', cursor: 'pointer' }}
                                        body={(rowData) => <div onClick={() => showDialog(rowData)}>{rowData.acuerdo}</div>}
                                    />
                                    <Column field="acta" header="ACTA" sortable filter filterPlaceholder="Buscar por acta" style={{ minWidth: '8rem', textAlign: 'center' }} />
                                    <Column header="FECHA ACTA" filterField="fechaacta" dataType="date" style={{ minWidth: '8rem', textAlign: 'center' }} body={actaBody} filter filterElement={dateFilter} />
                                    <Column field="area" header="AREA" sortable filter filterPlaceholder="Buscar por área" style={{ minWidth: '14rem', textAlign: 'center' }} />
                                    <Column field="jefatura" header="JEFATURA" sortable filter filterPlaceholder="Buscar por jefatura" style={{ minWidth: '14rem', textAlign: 'center' }} />
                                    <Column
                                        header="SEDE/FILIAL"
                                        filterField="sede"
                                        showFilterMatchModes={false}
                                        filterMenuStyle={{ width: '6rem' }}
                                        style={{ minWidth: '6rem', textAlign: 'center' }}
                                        body={sedeBody}
                                        filter
                                        filterElement={sedeFilter}
                                    />
                                    <Column header="ENCARGADO" filterField="encargado" showFilterMatchModes={false} filterMenuStyle={{ width: '18rem' }} style={{ minWidth: '18rem' }} body={encargadoBody} />
                                    <Column field="titulo" header="TITULO" sortable filter filterPlaceholder="Buscar por título" style={{ minWidth: '18rem' }} />
                                    <Column field="estado" header="ESTADO" filterMenuStyle={{ width: '8rem' }} style={{ minWidth: '6rem', textAlign: 'center' }} body={estadoBody} sortable filter filterElement={estadoFilter} />
                                    <Column field="situacion" header="SITUACION" filterMenuStyle={{ width: '8rem' }} style={{ minWidth: '6rem', textAlign: 'center' }} body={situacionBody} sortable filter filterElement={situacionFilter} />
                                </DataTable>
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
            </div>

            {registro && (
                <Dialog visible={dialog} style={{ width: '50vw' }} header={registro.acuerdo > 0 ? `Situación de acuerdo ${registro.acuerdo}` : `Pre-acuerdo ${registro.codigo}`} modal className="p-fluid" onHide={hideDialog}>
                    <span className="block text-4xl font-bold mb-1">{registro.titulo}</span>
                    <p className="mt-0 mb-4 text-700 line-height-3">{registro.descripcion}</p>

                    {registro.acuerdo > 0 && (
                        <DataTable value={movimientos} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem', margin: 'auto' }}>
                            <Column field="acuerdo" header="ACUERDO" alignHeader={'center'} style={{ textAlign: 'center' }} />
                            <Column field="descripcion" header="DESCRIPCION" alignHeader={'center'} style={{ textAlign: 'center' }} />
                            <Column field="comentario" header="COMENTARIO" alignHeader={'center'} style={{ textAlign: 'center' }} />
                            <Column field="fechaCreado" header="FECHA" alignHeader={'center'} style={{ textAlign: 'center' }} body={(rowData) => <span>{formatDate(rowData.fechaCreado)}</span>} />
                            <Column field="estado" header="ESTADO" alignHeader={'center'} style={{ textAlign: 'center' }} />
                        </DataTable>
                    )}
                </Dialog>
            )}
        </>
    );
};

export default RegatasAcuerdo;
