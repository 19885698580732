import React from 'react';

const AppFooter = () => {
    return (
        <div className="layout-footer">
            <div>
                <img src="assets/layout/images/logo-regatas-gray.svg" alt="regatas-layout" />
                <p>© Club de Regatas ''Lima'' 2022 - 2023</p>
            </div>
            <div className="footer-icons">
                <button type="button" className="p-link">
                    <a href="https://acuerdos.regataslima.pe" target="_self" rel="noreferrer">
                        <i className="pi pi-home"></i>
                    </a>
                </button>
                <button type="button" className="p-link">
                    <a href="https://regataslima.pe" target="_blank" rel="noreferrer">
                        <i className="pi pi-globe"></i>
                    </a>
                </button>
                <button type="button" className="p-link">
                    <a href="mailto:fcamarena@clubregatas.org.pe?subject=Nuevo Portal Regatas Admin">
                        <i className="pi pi-envelope"></i>
                    </a>
                </button>
            </div>
        </div>
    );
};

export default AppFooter;
